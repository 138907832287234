import { numberRegex } from "helpers/data-helpers/regex-helpers";
import { ILocation } from "services/resources/firebase/journey/types.d";
import { PartnerPayloadType } from "services/resources/firebase/partner/types.d";
import { object, string } from "yup";

export const PartnerFormValidationSchema = () => {
  return object().shape({
    fee: string()
      .matches(numberRegex, "Ingresa un valor numérico.")
      .required("Este campo es requerido."),
    name: string().required("Este campo es requerido."),
    planId: string().required("Selecciona un plan."),
    paymentStatus: string().required("Selecciona un estatus de pago."),
  });
};

export const INITIAL_VALUES: PartnerPayloadType = {
  fee: 0,
  location: {} as ILocation,
  locationAddress: "",
  name: "",
  planId: "",
  paymentStatus: "",
};
