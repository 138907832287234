import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";
import { IPartner } from "services/resources/firebase/partner/types.d";

export const DEFAULT_SECONDARY_DRIVER_REQUEST_DATA = {
  insurance: {
    document: "",
    status: VerificationStatusEnum.PENDING,
    expirationDate: new Date(),
  },
  vehicleInfo: {
    form: {
      brand: "",
      model: "",
      plates: "",
      year: new Date().getFullYear().toString(),
    },
    status: VerificationStatusEnum.PENDING,
    document: "",
  },
};

export const DEFAULT_MAP_SETTINGS = {
  center: {
    lat: 19.7008,
    lng: -101.1844,
  },
  zoom: 13.25,
};

export const DEFAULT_CIRCLE_OPTIONS = {
  strokeColor: "#FF0000",
  strokeOpacity: 1.0,
  strokeWeight: 2,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
  radius: 3000,
};

export const PRIVATE_DRIVERS_PARTNER: IPartner = {
  name: "Conductores privados",
  planId: "123e4567-e89b-12d3-a456-426614174000",
  paymentStatus: "pending",
  id: "private",
  created: { _nanoseconds: 0, _seconds: new Date().getSeconds() },
  updated: { _nanoseconds: 0, _seconds: new Date().getSeconds() },
  location: { latitude: 0, longitude: 0 },
  locationAddress: "",
  fee: 0,
};
