import { numberRegex } from "helpers/data-helpers/regex-helpers";
import { PlanPayloadType } from "services/resources/firebase/plan/types.d";
import { object, string } from "yup";

export const PlanFormValidationSchema = object().shape({
  commission: string()
    .matches(numberRegex, "Ingresa un valor numérico.")
    .required("Este campo es requerido."),
  price: string()
    .matches(numberRegex, "Ingresa un valor numérico.")
    .required("Este campo es requerido."),
  name: string().required("Este campo es requerido."),
  units: string()
    .matches(numberRegex, "Ingresa un valor numérico.")
    .required("Este campo es requerido."),
});

export const INITIAL_VALUES: PlanPayloadType = {
  commission: 0,
  name: "",
  price: 0,
  units: 0,
};
