import { StackProps, VStack } from "@chakra-ui/react";
import { UnitForm } from "components/forms";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { UnitPayloadType } from "services/resources/firebase/unit/types.d";

interface IUnitDetailUIProps extends StackProps {
  handleOnSubmit: (payload: UnitPayloadType) => Promise<void>;
  /**
   * Initial values for the form.
   */
  previousValues: UnitPayloadType;
  partners: IPartner[];
  handleOnUnitApprove: (payload: UnitPayloadType) => Promise<void>;
}

export const UnitDetailUI: React.FC<IUnitDetailUIProps> = (
  props
): JSX.Element => {
  const {
    handleOnSubmit,
    handleOnUnitApprove,
    previousValues,
    partners,
    ...rest
  } = props;

  return (
    <VStack p={5} w="95%" alignSelf="center" borderRadius={10} {...rest}>
      <UnitForm
        handleOnSubmit={handleOnSubmit}
        previousValues={previousValues}
        partners={partners}
        handleOnUnitApprove={handleOnUnitApprove}
      />
    </VStack>
  );
};
