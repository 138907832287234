import {
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Text,
  VStack,
  Textarea,
} from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import { DashboardPanel, PageContainer } from "components/elements";
import { isEmpty, isNil } from "lodash/fp";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "redux-service/slices";
import { CustomTimestamp } from "services/resources/firebase/types.d";
import { driverRequestResources } from "services/resources/firebase/driver-request/driver-request";
import {
  DriverRequestDocumentTypeEnum,
  IVerifyingDocument,
  VerificationStatusEnum,
} from "services/resources/firebase/driver-request/types.d";
import { userResources } from "services/resources/firebase/user";
import { zubeColors } from "styles/colors";
import { unitResources } from "services/resources/firebase/unit";
import { UnitStatusEnum } from "services/resources/firebase/unit/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { IRequestWithDriver } from "services/resources-recipes/types.d";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import {
  getDocList,
  getDocTypeByOrder,
  getGeneralData,
  getStatusLabel,
  getVehicleData,
} from "./helpers/data-helpers";
import { AppointmentModal } from "./components/AppointmentModal";
import {
  sendAppointmentNotification,
  sendApprovedDriverNotification,
  sendRejectedDocumentNotification,
} from "./helpers/notification-helpers";
import { format, getYear } from "date-fns";
import { es } from "date-fns/locale/es";

export const ReviewRequest: React.FC = (): JSX.Element => {
  const { requestId } = useParams();
  const { token, id: zubeAdminId } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const navigate = useNavigate();
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [requestWithDriver, setRequestWithDriver] =
    useState<IRequestWithDriver>({} as IRequestWithDriver);
  const [isAppointmentModalVisible, setIsAppointmentModalVisible] =
    useState<boolean>(false);

  const toggleIsAppointmentModalVisible = (): void => {
    setIsAppointmentModalVisible(!isAppointmentModalVisible);
  };

  const retrieveDriverRequest = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data: requestData } = await driverRequestResources.getById(
        requestId as string,
        token as string
      );
      const { data: userData } = await userResources.getById(
        requestId as string,
        token as string
      );
      if (!isNil(requestData) && !isNil(userData)) {
        setRequestWithDriver({
          request: requestData,
          driver: userData,
        });
      }
    } catch (e) {
      console.error("Error-retrieveDriverRequests: ", e);
      window.alert("Ocurrió un error inesperado.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const updateField = (
    type: DriverRequestDocumentTypeEnum,
    newStatus?: VerificationStatusEnum,
    newComment?: string
  ): void => {
    setRequestWithDriver({
      ...requestWithDriver,
      request: {
        ...requestWithDriver.request,
        [type]: {
          ...requestWithDriver.request[type],
          status: newStatus || requestWithDriver.request[type]?.status,
          comment: newComment || requestWithDriver.request[type]?.comment,
        },
      },
    });
  };

  const patchRequest = async (): Promise<void> => {
    const { created, id, updated, ...payload } = requestWithDriver.request;
    try {
      await driverRequestResources.patch(
        requestId as string,
        {
          ...payload,
          appointmentDate:
            payload.appointmentDate &&
            new Date(
              (payload.appointmentDate as CustomTimestamp)._seconds * 1000
            ).toISOString(),
        },
        token as string
      );
      // Check for the payload properties that are about the documents
      await Promise.allSettled(
        Object.entries(payload).map(async ([key, value]) => {
          // Check if the property is a request document and if the
          // status is rejected
          if (
            Object.keys(DriverRequestDocumentTypeEnum).includes(key) &&
            (value as IVerifyingDocument<unknown>).status ===
              VerificationStatusEnum.REJECTED
          ) {
            await sendRejectedDocumentNotification(
              requestWithDriver.driver,
              zubeAdminId,
              requestWithDriver.request.id,
              key as DriverRequestDocumentTypeEnum
            );
          }
          return;
        })
      );
      await retrieveDriverRequest();
    } catch (e) {
      window.alert("Ocurrió un error al actualizar la solicitud.");
      console.error("ReviewRequest-patchRequest", e);
    }
  };

  const enableDriver = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      // set request as completed
      await driverRequestResources.patchRequestStatus(
        VerificationStatusEnum.COMPLETED,
        requestId as string,
        token as string
      );
      // update driver verified flag and update score
      await userResources.enableDriver(requestId as string, token as string);
      // create unit doc with form payload
      await unitResources.create(
        {
          driver: requestId as string,
          brand: requestWithDriver.request.vehicleInfo.form.brand,
          model: requestWithDriver.request.vehicleInfo.form.model,
          plates: requestWithDriver.request.vehicleInfo.form.plates,
          year: Number(requestWithDriver.request.vehicleInfo.form.year),
          image: requestWithDriver.request.vehicleInfo.document,
          status: UnitStatusEnum.PENDING,
          requestStatus: VerificationStatusEnum.APPROVED,
          insurance: requestWithDriver.request.insurance,
          circulationCard: requestWithDriver.request.circulationCard,
          controlCard: requestWithDriver.request.controlCard,
          subscriptionId:
            requestWithDriver.driver.type === UserTypeEnum.PRIVATE_DRIVER
              ? "pending"
              : "public",
        },
        token as string
      );
      await sendApprovedDriverNotification(
        requestWithDriver.driver,
        zubeAdminId,
        requestWithDriver.request.id
      );
      await retrieveDriverRequest();
    } catch (e) {
      window.alert("Ocurrió un error al habilitar al conductor.");
      console.error("ReviewRequest-enableDriver", e);
    }
  };

  const handleOnPatchRequestAppointmentDate = async (
    date: Date
  ): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await driverRequestResources.patchRequestAppointmentDate(
        requestWithDriver.request.id,
        date.toISOString(),
        false,
        token as string
      );
      await sendAppointmentNotification(
        requestWithDriver.driver,
        zubeAdminId,
        requestWithDriver.request.id,
        format(date, "dd-MM-yyyy HH:mm")
      );
      await retrieveDriverRequest();
    } catch (e) {
      console.error(
        "handleOnPatchRequestAppointment: there was an issue updating the appointment"
      );
    }
  };

  const getReviewButtons = (): ReactNode => {
    return (
      <Stack
        flexDirection={isSmallScreen ? "column" : "row"}
        justifyContent="flex-end"
        w="90%"
        mb={5}
      >
        {requestWithDriver.request.status === VerificationStatusEnum.APPROVED ||
        requestWithDriver.request.status ===
          VerificationStatusEnum.APPOINTMENT ? (
          <>
            <Button
              _hover={{ bg: zubeColors.zubePurple.light }}
              bg={zubeColors.zubePurple.lighter}
              borderRadius={20}
              disabled={true}
              onClick={toggleIsAppointmentModalVisible}
            >
              {requestWithDriver.request.status ===
              VerificationStatusEnum.APPOINTMENT
                ? "Reagendar "
                : "Agendar "}
              cita
            </Button>
            <Button
              bg={zubeColors.locationBlue}
              borderRadius={20}
              disabled={true}
              onClick={enableDriver}
            >
              Habilitar conductor
            </Button>
          </>
        ) : requestWithDriver.request.status ===
            VerificationStatusEnum.PENDING ||
          requestWithDriver.request.status ===
            VerificationStatusEnum.SUBMITTED ? (
          <Button
            bg={zubeColors.zubeGreen}
            borderRadius={20}
            onClick={patchRequest}
          >
            Guardar
          </Button>
        ) : null}
      </Stack>
    );
  };

  useEffect(() => {
    retrieveDriverRequest();
    return () => {
      setLoadingModalVisible(false);
      setRequestWithDriver({} as IRequestWithDriver);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  return (
    <PageContainer
      pageHeader="Solicitud"
      loadingModalVisible={loadingModalVisible}
    >
      {!isNil(requestWithDriver.driver) ? (
        <AppointmentModal
          selectedDriver={requestWithDriver.driver}
          handleOnSelectDate={handleOnPatchRequestAppointmentDate}
          isOpen={isAppointmentModalVisible}
          onClose={toggleIsAppointmentModalVisible}
        />
      ) : null}
      {!isEmpty(requestWithDriver) ? (
        <VStack h="100%" alignItems="center" overflow="auto">
          <HStack justifyContent="flex-end" mt={5} w="90%">
            <Button
              bg="#ffa755"
              borderRadius={20}
              onClick={() => navigate("/requests")}
            >
              Regresar
            </Button>
          </HStack>
          <Grid
            gap={5}
            templateColumns={`repeat(${isSmallScreen ? 1 : 2}, 1fr)`}
            w="90%"
          >
            <GridItem>
              <DashboardPanel label="Datos Generales">
                <VStack w="100%">
                  <Divider mb={3} />
                  {getGeneralData(requestWithDriver).map((item) => (
                    <VStack w="100%" key={item.label}>
                      <HStack fontSize={12} spacing="auto" my={3} w="90%">
                        <Text fontWeight="bold" w="35%">
                          {item.label}
                        </Text>
                        {item.value === "check" ? (
                          <Text>&#9989;</Text>
                        ) : (
                          <Text>{item.value}</Text>
                        )}
                      </HStack>
                      <Divider />
                    </VStack>
                  ))}
                </VStack>
              </DashboardPanel>
            </GridItem>
            <GridItem>
              <DashboardPanel label="Datos Del Vehículo">
                <VStack w="100%">
                  <Divider mb={3} />
                  {getVehicleData(requestWithDriver).map((item) => (
                    <VStack w="100%" key={item.label}>
                      <HStack fontSize={12} spacing="auto" my={3} w="90%">
                        <Text fontWeight="bold" w="35%">
                          {item.label}
                        </Text>
                        <Text>{item.value}</Text>
                      </HStack>
                      <Divider />
                    </VStack>
                  ))}
                </VStack>
              </DashboardPanel>
            </GridItem>
          </Grid>
          <HStack h="15%" bg="#aaa" borderRadius={16} my={5} w="90%">
            <Text p={5}>Revisión de documentos</Text>
          </HStack>
          <Grid
            gap={5}
            mb={5}
            templateColumns={`repeat(${isSmallScreen ? 1 : 2}, 1fr)`}
            w="90%"
          >
            {getDocList(requestWithDriver).map((doc, index) => (
              <GridItem key={index}>
                <DashboardPanel label={getDocTypeByOrder(index).label}>
                  <VStack w="100%" alignItems="flex-start">
                    <Text
                      bg="#aaa"
                      borderRadius={16}
                      color="white"
                      fontWeight="bold"
                      px={2}
                      py={1}
                    >
                      {index === 1 &&
                      getYear(doc?.expirationDate as string) === 2200
                        ? "Permanente"
                        : `Fecha de expiración: ${format(
                            doc?.expirationDate as string,
                            "dd-MM-yyyy",
                            { locale: es }
                          )}`}
                    </Text>
                    <Divider />
                    {typeof doc?.document === "object" ? (
                      <HStack>
                        <Image src={doc.document.front} w="40%" />
                        <Image src={doc.document.back} w="40%" />
                      </HStack>
                    ) : (
                      <Image src={doc?.document} w="40%" />
                    )}
                    <Divider />
                    <Text fontWeight="bold">{`¿${
                      getDocTypeByOrder(index).label
                    } correcta?:`}</Text>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<BiChevronDown />}
                        w="100%"
                      >
                        {getStatusLabel(
                          doc?.status || VerificationStatusEnum.PENDING
                        )}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() =>
                            updateField(
                              getDocTypeByOrder(index).type,
                              VerificationStatusEnum.PENDING
                            )
                          }
                        >
                          Pendiente
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            updateField(
                              getDocTypeByOrder(index).type,
                              VerificationStatusEnum.REJECTED
                            )
                          }
                        >
                          Rechazado
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            updateField(
                              getDocTypeByOrder(index).type,
                              VerificationStatusEnum.APPROVED
                            )
                          }
                        >
                          Aprobado
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    {doc?.status === VerificationStatusEnum.REJECTED ? (
                      <VStack alignItems="flex-start" w="100%">
                        <Text fontWeight="bold">Motivo del Rechazo:</Text>
                        <Textarea
                          onBlur={(e) => {
                            e.target.value &&
                              updateField(
                                getDocTypeByOrder(index).type,
                                undefined,
                                e.target.value
                              );
                          }}
                          placeholder={doc.comment}
                        />
                      </VStack>
                    ) : null}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
            <GridItem>
              <DashboardPanel label="Foto del Carro">
                <VStack w="100%" alignItems="flex-start">
                  <Divider />
                  <Image
                    src={requestWithDriver.request.vehicleInfo.document}
                    w="40%"
                  />
                  {getVehicleData(requestWithDriver).map((item) => (
                    <VStack w="100%" key={item.label}>
                      <HStack fontSize={12} spacing="auto" my={3} w="90%">
                        <Text fontWeight="bold" w="35%">
                          {item.label}
                        </Text>
                        <Text>{item.value}</Text>
                      </HStack>
                      <Divider />
                    </VStack>
                  ))}
                  <Text fontWeight="bold">
                    ¿Los datos del vehículo son correctos?
                  </Text>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<BiChevronDown />}
                      w="100%"
                    >
                      {getStatusLabel(
                        requestWithDriver.request.vehicleInfo.status
                      )}
                    </MenuButton>
                    <MenuList>
                      <MenuItem>Pendiente</MenuItem>
                      <MenuItem
                        onClick={() =>
                          updateField(
                            DriverRequestDocumentTypeEnum.vehicleInfo,
                            VerificationStatusEnum.REJECTED
                          )
                        }
                      >
                        Rechazado
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          updateField(
                            DriverRequestDocumentTypeEnum.vehicleInfo,
                            VerificationStatusEnum.APPROVED
                          )
                        }
                      >
                        Aprobado
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  {requestWithDriver.request.vehicleInfo.status ===
                  VerificationStatusEnum.REJECTED ? (
                    <VStack alignItems="flex-start" w="100%">
                      <Text fontWeight="bold">Motivo del Rechazo:</Text>
                      <Textarea
                        onBlur={(e) => {
                          e.target.value &&
                            e.target.value !==
                              requestWithDriver.request.vehicleInfo.comment &&
                            updateField(
                              DriverRequestDocumentTypeEnum.vehicleInfo,
                              undefined,
                              e.target.value
                            );
                        }}
                        placeholder={
                          requestWithDriver.request.vehicleInfo.comment
                        }
                      />
                    </VStack>
                  ) : null}
                </VStack>
              </DashboardPanel>
            </GridItem>
          </Grid>
          {getReviewButtons()}
        </VStack>
      ) : null}
    </PageContainer>
  );
};
