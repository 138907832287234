import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import { type IPlan, type PlanPayloadType } from "./types.d";

const PLANS_API_URL = "/partnersAPI";

export const planResources = {
  create: async (
    plan: PlanPayloadType,
    token: string
  ): Promise<IAxiosResponse<IPlan>> =>
    firebaseInstance.post(
      `${PLANS_API_URL}/plan`,
      { plan },
      {
        headers: { Authorization: token },
      }
    ),

  delete: async (planId: string, token: string): Promise<IAxiosResponse<any>> =>
    firebaseInstance.delete(`${PLANS_API_URL}/plan`, {
      headers: { Authorization: token },
      params: { planId },
    }),

  getAll: async (token: string): Promise<IAxiosResponse<IPlan[]>> =>
    firebaseInstance.get(`${PLANS_API_URL}/`, {
      headers: { Authorization: token },
    }),

  patch: async (
    plan: PlanPayloadType,
    planId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${PLANS_API_URL}/plan`,
      { plan },
      {
        headers: { Authorization: token },
        params: { planId },
      }
    ),
};
