import { Button, HStack, StackProps, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";

import {
  INITIAL_VALUES,
  PlanFormValidationSchema,
} from "./helpers/form-helpers";
import { isEmpty } from "lodash/fp";
import { zubeColors } from "styles/colors";
import { PlanPayloadType } from "services/resources/firebase/plan/types.d";
import { InputField } from "components/inputs";

interface IPlanFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (payload: PlanPayloadType) => Promise<void>;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
  /**
   * Initial values for the form.
   */
  previousValues?: PlanPayloadType;
}

export const PlanForm: React.FC<IPlanFormProps> = (props) => {
  const { handleOnSubmit, onScreenChange, previousValues, ...rest } = props;

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={PlanFormValidationSchema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
      }) => (
        <VStack {...rest}>
          <InputField
            error={errors.name}
            label="Nombre"
            onChangeText={handleChange("name")}
            type="text"
            value={values.name}
          />
          <InputField
            error={errors.units}
            label="Máximo de unidades"
            onChangeText={handleChange("units")}
            type="text"
            value={values.units === 0 ? "" : values.units}
          />
          <InputField
            error={errors.price}
            label="Costo"
            onChangeText={handleChange("price")}
            type="text"
            value={values.price === 0 ? "" : values.price}
          />
          <InputField
            error={errors.commission}
            label="Porcentaje de comisión mensual"
            onChangeText={handleChange("commission")}
            type="text"
            value={values.commission === 0 ? "" : values.commission}
          />
          <HStack w="90%" mb={5}>
            <Button
              bg="white"
              borderRadius="full"
              borderColor={zubeColors.zubeOrange.light}
              borderWidth={1}
              onClick={onScreenChange}
              w="100%"
              color={zubeColors.zubeOrange.light}
            >
              Cancelar
            </Button>
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={!isEmpty(errors)}
              justifyContent="center"
              onClick={() => {
                if (isValid) handleSubmit();
              }}
              w="100%"
              color="white"
            >
              Guardar
            </Button>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
};

PlanForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
