import { type IFirebaseResource } from "services/resources/firebase/types.d";
import {
  IVerifyingDocument,
  VerificationStatusEnum,
} from "../driver-request/types.d";

export enum UnitStatusEnum {
  ACTIVE = "active",
  PENDING = "pending",
  CANCELLED = "cancelled",
}

export interface IUnit extends IFirebaseResource {
  /**
   * The brand of the vehicle.
   */
  brand: string;
  /**
   * The model of the vehicle.
   */
  model: string;
  /**
   * The plates of the vehicle.
   */
  plates: string;
  /**
   * The year of the vehicle.
   */
  year: number;
  /**
   * Image of the vehicle.
   */
  image: string;
  /**
   * Automovile insurance.
   */
  insurance: IVerifyingDocument<string>;
  /**
   * Circulation card.
   */
  circulationCard?: IVerifyingDocument<string>;
  /**
   * For public drivers. To be documented.
   */
  controlCard?: IVerifyingDocument<string>;
  /**
   * The id of the driver the vehicle belongs to. It might be optional,
   * as the units can be created from the admin app.
   */
  driver?: string;
  /**
   * Status of the subscription associated with this vehicle, only used for
   * private drivers (we store stripe's subscription status here).
   */
  status: UnitStatusEnum;
  /**
   * Id of the subscription attached to this unit. "pending" or "public"
   * when a unit is first created. Once a private driver begins the
   * trial period, a subscription is created and its id is stored here.
   */
  subscriptionId: string;
  /**
   * Client-defined identifier for the unit.
   */
  clientUnitId?: string;
  /**
   * To which partner does this unit belongs to,
   * if applicable.
   */
  partnerId?: string;
  /**
   * Review status of the unit when it's created.
   */
  requestStatus: VerificationStatusEnum;
}

export type UnitPayloadType = Omit<IUnit, "id" | "created" | "updated">;
