import {
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
  ZubeModal,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { isEmpty, isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import {
  IPartner,
  PartnerPayloadType,
} from "services/resources/firebase/partner/types.d";
import { IPlan } from "services/resources/firebase/plan/types.d";
import { planResources } from "services/resources/firebase/plan";
import { partnerResources } from "services/resources/firebase/partner";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { zubeColors } from "styles/colors";
import { PartnerForm } from "components/forms";
import { arrangePredictions } from "./helpers/array-helpers";
import { ILocation } from "services/resources/firebase/journey/types.d";
import {
  googleMapsResources,
  IPlaceAutocompletePrediction,
} from "services/resources/firebase/google-maps";

export const Partners: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [formType, setFormType] = useState<string>("");
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<IPartner>(
    {} as IPartner
  );
  const [predictedPlaces, setPredictedPlaces] = useState<
    Array<Partial<IPlaceAutocompletePrediction>>
  >([]);
  const [selectedPredictedPlace, setSelectedPredictedPlace] = useState<
    Partial<IPlaceAutocompletePrediction> | undefined
  >(undefined);
  const [partnerAddress, setPartnerAddress] = useState<string>("");
  const [partnerLocation, setPartnerLocation] = useState<ILocation | undefined>(
    undefined
  );

  const getStatusLabel = (status: string | undefined): string => {
    switch (status) {
      case "active":
        return "Activo";
      case "pending":
        return "Pendiente";
      default:
        return "";
    }
  };

  const getPlanLabel = (planId: string | undefined): string => {
    const plan = plans.find((plan) => plan.id === planId);
    return !isNil(plan) ? plan.name : "";
  };

  const handleOnRetrievePlans = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await planResources.getAll(token as string);
      if (!isNil(data)) setPlans(data);
    } catch (e) {
      console.error("Error-handleOnRetrievePlans: ", e);
    }
    setLoadingModalVisible(false);
  };

  const handleOnRetrievePartners = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await partnerResources.getAll(token as string);
      if (!isNil(data)) setPartners(data);
    } catch (e) {
      console.error("Error-handleOnRetrievePartners: ", e);
    }
    setLoadingModalVisible(false);
  };

  /**
   * Function to handle the creation and edition of partners.
   * @param partnerPayload
   */
  const handleOnSubmitPartner = async (
    partnerPayload: Omit<PartnerPayloadType, "location" | "locationAddress">
  ): Promise<void> => {
    onClose();
    setLoadingModalVisible(true);
    try {
      if (!isEmpty(selectedPartner)) {
        await partnerResources.patch(
          {
            ...partnerPayload,
            fee: Number(partnerPayload.fee),
            location: partnerLocation as ILocation,
            locationAddress: partnerAddress,
          },
          selectedPartner.id,
          token as string
        );
      } else {
        await partnerResources.create(
          {
            ...partnerPayload,
            fee: Number(partnerPayload.fee),
            location: partnerLocation as ILocation,
            locationAddress: partnerAddress,
          },
          token as string
        );
      }
    } catch (e) {
      console.error("Error-handleOnSubmitPartner: ", e);
      window.alert("Ocurrió un error al crear/editar el partner.");
    } finally {
      setFormType("");
      setSelectedPartner({} as IPartner);
      handleOnRetrievePartners();
      setLoadingModalVisible(false);
    }
  };

  const handleOnDeletePartner = async (partnerId: string): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await partnerResources.delete(partnerId, token as string);
    } catch (e) {
      console.error("Error-handleOnDeletePartner: ", e);
      window.alert("Ocurrió un error al eliminar el partner.");
    } finally {
      handleOnRetrievePartners();
      setLoadingModalVisible(false);
    }
  };

  const handleOnClearPredictions = () => {
    setSelectedPredictedPlace(undefined);
    setPartnerLocation(undefined);
    setPredictedPlaces([]);
  };

  /**
   * Function that from a given text, retrieves possible
   * matching predictions to be shown to the user.
   * @param text
   */
  const handlePrediction = async (
    predictionInput: string,
    deletedChar: boolean
  ): Promise<void> => {
    // We first verify if the user is deleting characters, to just update
    // the input and not call the API.
    if (deletedChar) {
      setPartnerAddress(predictionInput);
      handleOnClearPredictions();
      return;
    } else {
      setPartnerAddress(predictionInput);
    }
    try {
      // Based on the written text, get possible places' predictions
      const { data } =
        await googleMapsResources.getPlaceAutocompletePredictions(
          predictionInput,
          token as string
        );
      // If all went well, store data
      if (!isNil(data) && data.status === "OK") {
        setPredictedPlaces(arrangePredictions(data.predictions));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        "Error-handlePrediction: there was an issue handling prediction."
      );
    }
  };

  /**
   * Function that handles the prediction selection. This means, to retrieve
   * a location based on a chosen address.
   * @param prediction
   */
  const handleOnConfirmPrediction = async (
    prediction: Partial<IPlaceAutocompletePrediction>
  ): Promise<void> => {
    setSelectedPredictedPlace(prediction);
    try {
      // Based on the place id, retrieve place location
      const { data } = await googleMapsResources.getPlaceLocation(
        prediction.place_id as string,
        token as string
      );

      if (!isNil(data) && data.status === "OK") {
        // Create a reusable payload for either of the cases
        const outputLocationPrediction = {
          latitude: data.result.geometry.location.lat,
          longitude: data.result.geometry.location.lng,
        };
        setPartnerLocation(outputLocationPrediction);
        setPartnerAddress(
          `${prediction.structured_formatting?.main_text}, ${prediction.structured_formatting?.secondary_text}`
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error-handleOnConfirmPrediction: ", e);
    }
  };

  const getPreviousValues = (): PartnerPayloadType => {
    if (!isEmpty(selectedPartner)) {
      const { created, id, updated, ...selectedValues } = selectedPartner;
      return selectedValues;
    } else return {} as PartnerPayloadType;
  };

  const columns: ITypedListTableColumn<IPartner>[] = [
    {
      accessorKey: "name",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.name}</Text>,
    },
    {
      header: "Cuota mensual",
      cell: ({ row }) => <Text>${row.original.fee.toFixed(2)} MXN</Text>,
    },
    {
      header: "Plan",
      cell: ({ row }) => <Text>{getPlanLabel(row.original.planId)}</Text>,
    },
    {
      header: "Estatus de Pago",
      cell: ({ row }) => (
        <Text>{getStatusLabel(row.original.paymentStatus)}</Text>
      ),
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <HStack>
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            onClick={() => {
              setPartnerAddress(row.original.locationAddress);
              setPartnerLocation(row.original.location);
              setSelectedPartner(row.original);
              setFormType("edit");
              onOpen();
            }}
            rounded="full"
            fontSize={12}
          >
            Editar
          </Button>
          <Button
            bg="red"
            color="white"
            fontSize={12}
            onClick={() => handleOnDeletePartner(row.original.id)}
            rounded="full"
          >
            Eliminar
          </Button>
        </HStack>
      ),
    },
  ];

  useEffect(() => {
    handleOnRetrievePlans();
    handleOnRetrievePartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Partners"
      loadingModalVisible={loadingModalVisible}
    >
      <ZubeModal
        isOpen={isOpen}
        onClose={() => {
          setSelectedPartner({} as IPartner);
          onClose();
        }}
        title={formType === "create" ? "Crear Partner" : "Editar Partner"}
      >
        <PartnerForm
          handleOnSubmit={handleOnSubmitPartner}
          onScreenChange={() => {
            setSelectedPartner({} as IPartner);
            onClose();
          }}
          previousValues={getPreviousValues()}
          plans={plans}
          partnerAddress={partnerAddress}
          partnerLocation={partnerLocation}
          onChangeAddress={handlePrediction}
          predictedPlaces={predictedPlaces}
          onConfirmPrediction={handleOnConfirmPrediction}
          selectedPredictedPlace={selectedPredictedPlace}
          onClearPredictions={handleOnClearPredictions}
        />
      </ZubeModal>
      <HStack justifyContent="flex-end" w="95%">
        <Button
          bg={zubeColors.zubeOrange.light}
          borderRadius="full"
          color="white"
          mt={5}
          onClick={() => {
            setFormType("create");
            onOpen();
          }}
        >
          Registrar Partner
        </Button>
      </HStack>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {partners.map((partner, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={partner.name}
                  rightElement={
                    <HStack>
                      <Button
                        color="white"
                        bg={zubeColors.zubeGreen}
                        onClick={() => {
                          setSelectedPartner(partner);
                          setFormType("edit");
                          onOpen();
                        }}
                        rounded="full"
                        fontSize={12}
                      >
                        Editar
                      </Button>
                      <Button
                        bg="red"
                        color="white"
                        fontSize={12}
                        onClick={() => handleOnDeletePartner(partner.id)}
                        rounded="full"
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  }
                >
                  <VStack w="100%">
                    {[
                      {
                        label: "Plan",
                        value: getPlanLabel(partner.planId),
                      },
                      {
                        label: "Couta mensual",
                        value: `$${partner.fee.toFixed(2)} MXN`,
                      },
                      {
                        label: "Estatus de Pago",
                        value: getStatusLabel(partner.paymentStatus),
                      },
                    ].map((item) => (
                      <>
                        <Divider />
                        <HStack fontSize={12} spacing="auto" my={3} w="90%">
                          <Text fontWeight="bold" w="35%">
                            {item.label}
                          </Text>
                          <Text>{item.value}</Text>
                        </HStack>
                      </>
                    ))}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="90%" alignSelf="center">
          <Center bg="white" w="90%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={partners}
                columns={columns as ColumnDef<object>[]}
                w="90%"
                h="90%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
