import {
  VStack,
  Grid,
  GridItem,
  Divider,
  HStack,
  Button,
  Center,
  Text,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { DashboardPanel, DashboardTable } from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { IUnitWithDriver } from "services/resources-recipes/types.d";
import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";
import { zubeColors } from "styles/colors";

interface IUnitUIProps {
  unitsWithDrivers: IUnitWithDriver[];
  getStatus: (status: VerificationStatusEnum) => {
    color: string;
    label: string;
  };
  handleOnDeleteUnit: (unitId: string) => Promise<void>;
  columns: ITypedListTableColumn<IUnitWithDriver>[];
  handleOnEditUnit: (unitId: string) => void;
  handleOnUnitCreateShow: () => void;
}

export const UnitUI: React.FC<IUnitUIProps> = (props): JSX.Element => {
  const {
    handleOnEditUnit,
    unitsWithDrivers,
    getStatus,
    handleOnDeleteUnit,
    handleOnUnitCreateShow,
    columns,
  } = props;

  const isSmallScreen = useSmallScreen();
  return (
    <>
      <HStack display="flex" w="100%" justifyContent="flex-end" p={5}>
        <Button
          color="white"
          bg={zubeColors.zubePurple.dark}
          rounded="3xl"
          mr={5}
          onClick={handleOnUnitCreateShow}
        >
          Agregar nuevo
        </Button>
      </HStack>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {unitsWithDrivers.map((unitWithDriver, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={`${unitWithDriver.brand} ${unitWithDriver.model} ${unitWithDriver.plates}`}
                >
                  <VStack w="100%">
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Número de unidad
                      </Text>
                      <Text>{unitWithDriver.clientUnitId}</Text>
                    </HStack>
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Partner
                      </Text>
                      <Text>Sin dueño</Text>
                    </HStack>
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Conductor Actual
                      </Text>
                      <Text>{unitWithDriver.driver.fullName}</Text>
                    </HStack>
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Estatus
                      </Text>
                      <Text
                        bg={getStatus(unitWithDriver.requestStatus).color}
                        borderRadius={16}
                        color="white"
                        textAlign="center"
                        px={2}
                        py={2}
                      >
                        {getStatus(unitWithDriver.requestStatus).label}
                      </Text>
                    </HStack>
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="45%">
                        Datos Del Vehículo
                      </Text>
                      <VStack>
                        <HStack>
                          <Text fontWeight="bold">Modelo: </Text>
                          <Text>{unitWithDriver.model}</Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight="bold">Fabricante: </Text>
                          <Text>{unitWithDriver.brand}</Text>
                        </HStack>
                      </VStack>
                    </HStack>
                    <Divider />
                    <HStack>
                      <Button
                        color="white"
                        bg={zubeColors.zubeGreen}
                        rounded="full"
                        fontSize={12}
                        onClick={() => handleOnEditUnit(unitWithDriver.id)}
                      >
                        Editar
                      </Button>
                      <Button
                        bg="red"
                        color="white"
                        fontSize={12}
                        onClick={() => handleOnDeleteUnit(unitWithDriver.id)}
                        rounded="full"
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="100%" alignSelf="center">
          <Center bg="white" w="95%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={unitsWithDrivers}
                columns={columns as ColumnDef<object>[]}
                w="95%"
                h="95%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </>
  );
};
