import { getDriverRequestDocumentName } from "helpers/data-helpers/string-helpers";
import { isNil } from "lodash/fp";
import { DriverRequestDocumentTypeEnum } from "services/resources/firebase/driver-request/types.d";
import { notificationsResources } from "services/resources/firebase/notification";
import { NotificationPayloadType } from "services/resources/firebase/notification/types.d";
import { IUser } from "services/resources/firebase/user/types.d";

export const sendAppointmentNotification = async (
  driver: IUser,
  zubeAdminUserId: string,
  requestId: string,
  appointmentDate: string
): Promise<void> => {
  try {
    if (isNil(driver.expoDeviceId)) {
      throw new Error("Device id is required to send a notification");
    }
    const notification: NotificationPayloadType = {
      body: `Se ha programado una cita en el día y hora: ${appointmentDate} para continuar con tu proceso de solicitud.`,
      data: {
        collection: "driversRequests",
        resourceId: requestId,
        silent: false,
        target: driver.id,
        targetScreen: "driverVerification",
        triggeredBy: zubeAdminUserId,
      },
      priority: "high",
      sound: "default",
      title: "Cita confirmada",
      to: driver.expoDeviceId,
    };
    await notificationsResources.create(notification);
  } catch (e) {
    console.error(
      "sendAppointmentNotification: There was an issue sending appointment notification."
    );
  }
};

export const sendApprovedDriverNotification = async (
  driver: IUser,
  zubeAdminUserId: string,
  requestId: string
): Promise<void> => {
  try {
    if (isNil(driver.expoDeviceId)) {
      throw new Error("Device id is required to send a notification");
    }
    const notification: NotificationPayloadType = {
      body: `¡Felicidades ${driver.fullName}! Has sido aprobado como conductor.`,
      data: {
        collection: "driversRequests",
        resourceId: requestId,
        silent: false,
        target: driver.id,
        targetScreen: "driverVerification",
        triggeredBy: zubeAdminUserId,
      },
      priority: "high",
      sound: "default",
      title: "Conductor aprobado",
      to: driver.expoDeviceId,
    };
    await notificationsResources.create(notification);
  } catch (e) {
    console.error(
      "sendApprovedDriverNotification: There was an issue sending approved driver notification."
    );
  }
};

export const sendRejectedDocumentNotification = async (
  driver: IUser,
  zubeAdminUserId: string,
  requestId: string,
  rejectedDocument: DriverRequestDocumentTypeEnum
): Promise<void> => {
  try {
    if (isNil(driver.expoDeviceId)) {
      throw new Error("Device id is required to send a notification");
    }
    const notification: NotificationPayloadType = {
      body: `Hola, ${
        driver.fullName
      }. Ha habido un problema con tu documento "${
        getDriverRequestDocumentName(rejectedDocument).readableDocumentName
      }". Por favor, accede a Zube para revisar detalles.`,
      data: {
        collection: "driversRequests",
        resourceId: requestId,
        silent: false,
        target: driver.id,
        targetScreen: "driverVerification",
        triggeredBy: zubeAdminUserId,
      },
      priority: "high",
      sound: "default",
      title: "Documento rechazado",
      to: driver.expoDeviceId,
    };
    await notificationsResources.create(notification);
  } catch (e) {
    console.error(
      "sendRejectedDocumentNotification: There was an issue sending approved driver notification."
    );
  }
};
