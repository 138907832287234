import {
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
  ZubeModal,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { isEmpty, isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { planResources } from "services/resources/firebase/plan";
import {
  IPlan,
  PlanPayloadType,
} from "services/resources/firebase/plan/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { zubeColors } from "styles/colors";
import { PlanForm } from "components/forms";

export const Plans: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formType, setFormType] = useState<string>("");
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<IPlan>({} as IPlan);

  const handleOnRetrievePlans = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await planResources.getAll(token as string);
      if (!isNil(data)) setPlans(data);
    } catch (e) {
      console.error("Error-handleOnRetrievePlans: ", e);
    }
    setLoadingModalVisible(false);
  };

  /**
   * Function to handle the creation and edition of plans.
   * @param planPayload
   */
  const handleOnSubmitPlan = async (
    planPayload: PlanPayloadType
  ): Promise<void> => {
    onClose();
    setLoadingModalVisible(true);
    try {
      !isEmpty(selectedPlan)
        ? await planResources.patch(
            {
              ...planPayload,
              price: Number(planPayload.price),
              units: Number(planPayload.units),
            },
            selectedPlan.id,
            token as string
          )
        : await planResources.create(
            {
              ...planPayload,
              price: Number(planPayload.price),
              units: Number(planPayload.units),
            },
            token as string
          );
    } catch (e) {
      console.error("Error-handleOnSubmitPlan: ", e);
      window.alert("Ocurrió un error al crear/editar el plan.");
    } finally {
      setFormType("");
      setSelectedPlan({} as IPlan);
      handleOnRetrievePlans();
      setLoadingModalVisible(false);
    }
  };

  const handleOnDeletePlan = async (planId: string): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await planResources.delete(planId, token as string);
    } catch (e) {
      console.error("Error-handleOnDeletePlan: ", e);
      window.alert("Ocurrió un error al eliminar el plan.");
    } finally {
      handleOnRetrievePlans();
      setLoadingModalVisible(false);
    }
  };

  const columns: ITypedListTableColumn<IPlan>[] = [
    {
      accessorKey: "name",
      header: "Plan",
      cell: ({ row }) => <Text>{row.original.name}</Text>,
    },
    {
      header: "Máximo de unidades",
      cell: ({ row }) => <Text>{row.original.units}</Text>,
    },
    {
      header: "Costo",
      cell: ({ row }) => <Text>{row.original.price}</Text>,
    },
    {
      header: "Comisión mensual",
      cell: ({ row }) => <Text>{row.original.commission}%</Text>,
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <HStack>
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            onClick={() => {
              setSelectedPlan(row.original);
              setFormType("edit");
              onOpen();
            }}
            rounded="full"
            fontSize={12}
          >
            Editar
          </Button>
          <Button
            bg="red"
            color="white"
            fontSize={12}
            onClick={() => handleOnDeletePlan(row.original.id)}
            rounded="full"
          >
            Eliminar
          </Button>
        </HStack>
      ),
    },
  ];

  const getPreviousValues = (): PlanPayloadType => {
    const { created, id, updated, ...selectedValues } = selectedPlan;
    return selectedValues;
  };

  useEffect(() => {
    handleOnRetrievePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Planes"
      loadingModalVisible={loadingModalVisible}
    >
      <ZubeModal
        isOpen={isOpen}
        onClose={() => {
          setSelectedPlan({} as IPlan);
          onClose();
        }}
        title={formType === "create" ? "Crear Plan" : "Editar Plan"}
      >
        <PlanForm
          handleOnSubmit={handleOnSubmitPlan}
          onScreenChange={() => {
            setSelectedPlan({} as IPlan);
            onClose();
          }}
          previousValues={getPreviousValues()}
        />
      </ZubeModal>
      <HStack justifyContent="flex-end" w="95%">
        <Button
          bg={zubeColors.zubeOrange.light}
          borderRadius="full"
          color="white"
          mt={5}
          onClick={() => {
            setFormType("create");
            onOpen();
          }}
        >
          Crear Plan
        </Button>
      </HStack>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {plans.map((plan, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={plan.name}
                  rightElement={
                    <HStack>
                      <Button
                        color="white"
                        bg={zubeColors.zubeGreen}
                        onClick={() => {
                          setSelectedPlan(plan);
                          setFormType("edit");
                          onOpen();
                        }}
                        rounded="full"
                        fontSize={12}
                      >
                        Editar
                      </Button>
                      <Button
                        bg="red"
                        color="white"
                        fontSize={12}
                        onClick={() => handleOnDeletePlan(plan.id)}
                        rounded="full"
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  }
                >
                  <VStack w="100%">
                    {[
                      {
                        label: "Máximo de unidades",
                        value: plan.units,
                      },
                      {
                        label: "Costo",
                        value: plan.price,
                      },
                      {
                        label: "Comisión mensual",
                        value: `${plan.commission}%`,
                      },
                    ].map((item) => (
                      <>
                        <Divider />
                        <HStack fontSize={12} spacing="auto" my={3} w="90%">
                          <Text fontWeight="bold" w="35%">
                            {item.label}
                          </Text>
                          <Text>{item.value}</Text>
                        </HStack>
                      </>
                    ))}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="90%" alignSelf="center">
          <Center bg="white" w="90%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={plans}
                columns={columns as ColumnDef<object>[]}
                w="90%"
                h="90%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
