import {
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Select,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { isEmpty, isNil } from "lodash/fp";
import { zubeColors } from "styles/colors";
import { InputField } from "components/inputs";
import { PartnerPayloadType } from "services/resources/firebase/partner/types.d";
import { IPlan } from "services/resources/firebase/plan/types.d";
import {
  INITIAL_VALUES,
  PartnerFormValidationSchema,
} from "./helpers/form-helpers";
import { IPlaceAutocompletePrediction } from "services/resources/firebase/google-maps";
import { ILocation } from "services/resources/firebase/journey/types.d";

interface IPartnerFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (
    payload: Omit<PartnerPayloadType, "location" | "locationAddress">
  ) => Promise<void>;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
  /**
   * Initial values for the form.
   */
  previousValues?: PartnerPayloadType;
  /**
   * Handler function we use for detecting any text changes for getting possible
   * predictions for either origin or detination.
   */
  onChangeAddress: (text: string, didDeleteCharacter: boolean) => void;
  partnerAddress: string;
  plans: IPlan[];
  partnerLocation: ILocation | undefined;
  predictedPlaces: Partial<IPlaceAutocompletePrediction>[];
  onConfirmPrediction: (
    prediction: Partial<IPlaceAutocompletePrediction>
  ) => Promise<void>;
  selectedPredictedPlace: Partial<IPlaceAutocompletePrediction> | undefined;
  onClearPredictions: () => void;
}

export const PartnerForm: React.FC<IPartnerFormProps> = (props) => {
  const {
    handleOnSubmit,
    onScreenChange,
    previousValues,
    onChangeAddress,
    plans,
    partnerLocation,
    partnerAddress,
    onConfirmPrediction,
    predictedPlaces,
    selectedPredictedPlace,
    onClearPredictions,
    ...rest
  } = props;

  const [deletedChar, setDeletedChar] = useState<boolean>(false);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace") {
      setDeletedChar(true);
    }
  };

  const getIsPlanDisabledStatus = (
    values: PartnerPayloadType,
    errors: FormikErrors<PartnerPayloadType>
  ): boolean => {
    const { paymentStatus, ...restOfErrors} = errors;
    const presentErrors = Object.values(restOfErrors).filter(
      (value) => !isEmpty(value)
    );
    if (isEmpty(values.planId) || !isEmpty(presentErrors)) {
      return true;
    }
    return false;
  };

  const isEditing = !isEmpty(previousValues) && !isNil(previousValues);
  const isLocationValid = !isNil(partnerLocation);

  const matchingPlan = plans.find((plan) => previousValues?.planId === plan.id);
  const existingPlan = !isNil(matchingPlan) ? matchingPlan.name : '';

  useEffect(() => {
    return () => {
      onChangeAddress("", false);
      onClearPredictions();
      setDeletedChar(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(partnerAddress)) {
      setDeletedChar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerAddress]);

  return (
    <Formik
      initialValues={isEditing ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={PartnerFormValidationSchema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
      }) => (
        <VStack {...rest}>
          <InputField
            error={errors.name}
            label="Nombre"
            onChangeText={handleChange("name")}
            type="text"
            value={values.name}
          />
          <InputField
            error={errors.fee}
            label="Cuota Mensual"
            onChangeText={handleChange("fee")}
            type="text"
            value={values.fee === 0 ? "" : values.fee}
          />
          <Text alignSelf="flex-start" fontSize={14} fontWeight="bold">
            Plan
          </Text>
          <Select
            mb={4}
            onChange={(e) => {
              setFieldValue("planId", e.target.selectedOptions[0].value);
            }}
            placeholder={!isEditing ? "Selecciona un plan" : existingPlan}
            value={values.planId}
          >
            {plans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.name}
              </option>
            ))}
          </Select>
          <FormControl isInvalid={!isEmpty(errors.planId)} mt={-7}>
            <HStack justifyContent="center">
              <FormErrorMessage>
                <FormErrorIcon color="salmon" h={4} ml={3} />
                {errors.planId}
              </FormErrorMessage>
            </HStack>
          </FormControl>
          <Text alignSelf="flex-start" fontSize={14} fontWeight="bold" mt={3}>
            Estatus de Pago
          </Text>
          <Select
            mb={4}
            onChange={(e) => {
              setFieldValue("paymentStatus", e.target.selectedOptions[0].value);
            }}
            placeholder="Selecciona un estatus"
            value={values.paymentStatus}
            isDisabled={getIsPlanDisabledStatus(values, errors)}
          >
            <option value="active">Activo</option>
            <option value="pending">Pendiente</option>
          </Select>
          <FormControl
            isInvalid={!isEmpty(errors.paymentStatus)}
            mt={-7}
            mb={3}
          >
            <HStack justifyContent="center">
              <FormErrorMessage>
                <FormErrorIcon color="salmon" h={4} ml={3} />
                {errors.paymentStatus}
              </FormErrorMessage>
            </HStack>
          </FormControl>
          <InputField
            error={
              !isEditing && isNil(partnerLocation)
                ? "Debes confirmar una dirección para el partner"
                : undefined
            }
            label="Dirección"
            onChangeText={(text) => onChangeAddress(text, deletedChar)}
            type="text"
            value={partnerAddress}
            onKeyDown={handleKeyPress}
          />
          {(!isEditing &&
            !isEmpty(predictedPlaces) &&
            isNil(partnerLocation)) ||
          (isEditing &&
            !isEmpty(predictedPlaces) &&
            JSON.stringify(partnerLocation) ===
              JSON.stringify(previousValues.location)) ? (
            <Select
              mb={4}
              onChange={(e) =>
                onConfirmPrediction(
                  predictedPlaces.find(
                    (predictedPlace) =>
                      predictedPlace.place_id ===
                      e.target.selectedOptions[0].value
                  ) as IPlaceAutocompletePrediction
                )
              }
              placeholder="dirección"
              value={selectedPredictedPlace?.place_id}
            >
              {predictedPlaces.map((predictedPlace) => (
                <option
                  key={predictedPlace.place_id}
                  value={predictedPlace.place_id}
                >
                  <Text>
                    {`${predictedPlace.structured_formatting?.main_text}, ${predictedPlace.structured_formatting?.secondary_text}`}
                  </Text>
                </option>
              ))}
            </Select>
          ) : null}
          <HStack w="90%" mb={5}>
            <Button
              bg="white"
              borderRadius="full"
              borderColor={zubeColors.zubeOrange.light}
              borderWidth={1}
              onClick={onScreenChange}
              w="100%"
              color={zubeColors.zubeOrange.light}
            >
              Cancelar
            </Button>
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={
                // If there are form errors
                !isEmpty(errors) ||
                // If creating and no location
                (!isEditing && isNil(partnerLocation)) ||
                // If editing
                (isEditing &&
                  // No form changes
                  JSON.stringify(previousValues) === JSON.stringify(values) &&
                  // No location changes
                  (JSON.stringify(partnerLocation) ===
                    JSON.stringify(previousValues.location) ||
                    // No valid location
                    !isLocationValid))
              }
              justifyContent="center"
              onClick={() => {
                if (isValid) handleSubmit();
              }}
              w="100%"
              color="white"
            >
              {isEditing ? "Actualizar" : "Crear"}
            </Button>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
};

PartnerForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
