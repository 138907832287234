import { Button, HStack, StackProps, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { isEmpty, isNil } from "lodash/fp";
import { zubeColors } from "styles/colors";
import {
  INITIAL_VALUES,
  UnitFormValidationSchema,
} from "./helpers/form-helpers";
import { UnitPayloadType } from "services/resources/firebase/unit/types.d";
import { UnitData } from "./components/UnitData";
import { UnitPicture } from "./components/UnitPicture";
import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";
import { IPartner } from "services/resources/firebase/partner/types.d";

interface IUnitFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (payload: UnitPayloadType) => Promise<void>;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
  /**
   * Initial values for the form.
   */
  previousValues?: UnitPayloadType;
  partners: IPartner[];
  handleOnUnitApprove: (payload: UnitPayloadType) => Promise<void>;
}

export const UnitForm: React.FC<IUnitFormProps> = (props) => {
  const {
    handleOnSubmit,
    onScreenChange,
    previousValues,
    partners,
    handleOnUnitApprove,
    ...rest
  } = props;

  const isCreating = isNil(previousValues) || isEmpty(previousValues);

  const standardHeight = "650px";
  const rejectReasonHeight = "750px";

  const getAreDocumentsValid = (values: UnitPayloadType): boolean => {
    const areStandardDocumentsApproved =
      values.circulationCard?.status === VerificationStatusEnum.APPROVED &&
      values.insurance.status === VerificationStatusEnum.APPROVED;

    return (
      areStandardDocumentsApproved ||
      (areStandardDocumentsApproved &&
        !isNil(values.controlCard) &&
        values.controlCard.status === VerificationStatusEnum.APPROVED)
    );
  };

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={UnitFormValidationSchema}
    >
      {({ errors, handleSubmit, isValid, setFieldValue, values }) => (
        <VStack w="100%" {...rest}>
          <UnitData
            unitData={values}
            setFieldValue={setFieldValue}
            partners={partners}
          />
          <HStack
            justifyContent="space-between"
            w="100%"
            h={
              values.circulationCard?.status !== VerificationStatusEnum.REJECTED
                ? standardHeight
                : rejectReasonHeight
            }
          >
            <UnitPicture
              label="Imagen del vehículo"
              unitPictureURL={values.image}
              handleOnImageSelect={(uri) => {
                setFieldValue("image", uri);
              }}
              id="image"
            />
            <UnitPicture
              label="Tarjeta de circulación"
              unitPictureURL={values.circulationCard?.document}
              showStatusDropdown={!isCreating}
              dropdownLabel="¿Tarjeta de circulación correcta?"
              statusValue={values.circulationCard?.status}
              handleOnStatusChange={(newStatus) =>
                setFieldValue("circulationCard.status", newStatus)
              }
              handleOnImageSelect={(uri) => {
                setFieldValue("circulationCard.document", uri);
              }}
              id="circulationCard"
              rejectCommentValue={values.circulationCard?.comment}
              handleOnRejectCommentChange={(newComment) =>
                setFieldValue("circulationCard.comment", newComment)
              }
            />
          </HStack>
          <HStack
            justifyContent="space-between"
            w="100%"
            h={
              values.controlCard?.status === VerificationStatusEnum.REJECTED ||
              values.insurance.status === VerificationStatusEnum.REJECTED
                ? rejectReasonHeight
                : standardHeight
            }
          >
            <UnitPicture
              label="Tarjeta de control"
              unitPictureURL={values.controlCard?.document}
              showStatusDropdown={!isCreating}
              dropdownLabel="¿Tarjeta de control correcta?"
              statusValue={values.controlCard?.status}
              handleOnStatusChange={(newStatus) =>
                setFieldValue("controlCard.status", newStatus)
              }
              handleOnImageSelect={(uri) => {
                setFieldValue("controlCard.document", uri);
              }}
              id="controlCard"
              rejectCommentValue={values.controlCard?.comment}
              handleOnRejectCommentChange={(newComment) =>
                setFieldValue("controlCard.comment", newComment)
              }
            />
            <UnitPicture
              label="Póliza de seguro"
              unitPictureURL={values.insurance.document}
              showStatusDropdown={!isCreating}
              dropdownLabel="¿Póliza de seguro correcta?"
              statusValue={values.insurance?.status}
              handleOnStatusChange={(newStatus) =>
                setFieldValue("insurance.status", newStatus)
              }
              handleOnImageSelect={(uri) => {
                setFieldValue("insurance.document", uri);
              }}
              id="insurance"
              rejectCommentValue={values.insurance?.comment}
              handleOnRejectCommentChange={(newComment) =>
                setFieldValue("insurance.comment", newComment)
              }
            />
          </HStack>
          <HStack
            justifyContent="flex-end"
            mt={3}
            w="100%"
            bg="white"
            p={5}
            borderRadius={10}
          >
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={!isEmpty(errors)}
              justifyContent="center"
              onClick={() => {
                if (isCreating) {
                  setFieldValue(
                    "circulationCard.status",
                    VerificationStatusEnum.APPROVED
                  );
                  setFieldValue(
                    "controlCard.status",
                    VerificationStatusEnum.APPROVED
                  );
                  setFieldValue(
                    "insurance.status",
                    VerificationStatusEnum.APPROVED
                  );
                }
                if (isValid) handleSubmit();
              }}
              w="10%"
              color="white"
            >
              Guardar
            </Button>
            {!isCreating ? (
              <Button
                bg={zubeColors.zubeGreen}
                borderRadius="full"
                isDisabled={!getAreDocumentsValid(values)}
                justifyContent="center"
                onClick={() => handleOnUnitApprove(values)}
                color="white"
              >
                Habilitar Unidad
              </Button>
            ) : null}
          </HStack>
        </VStack>
      )}
    </Formik>
  );
};
